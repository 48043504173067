/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { updateTransactionMetadata } from '../../../../util/api';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';
import { QrReader } from 'react-qr-reader';


import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  Button,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import { useWeb3 } from '../../../../context/Web3';

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>

          <div className={css.customLinksWrapper}>{extraLinks}</div>

          <div className={css.spacer} />
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';
  const { hasWeb3, client, web3Handler, userRentalAgreement, setUserRentalAgreement, getRentalAgreement, handleCheckIn, handleCheckOut, getTokenId, rentalContract } = useWeb3();
  const [showQrReader, setShowQrReader] = useState(false);


  const fetchUpdateTransactionMetadata = async (transactionId) => {
    // console.log(transactionId);
    const body = {
      transactionId
    };
    
    try {
      const response = await updateTransactionMetadata(body);
      return response.data;
    } catch (e) {
      // console.log(e);
      throw e; 
    }
  };


  
const handleQrScan = async (result) => {
  if (result) {
    const jsonString = result?.text;
    const qrCodeDict = JSON.parse(jsonString);

    if (qrCodeDict.author_id === currentUser.id.uuid) {
      // console.log("This user can verify the qrCode");
      const transactionId = qrCodeDict.transaction_id;
      const response = await updateTransactionMetadata({ transactionId: transactionId, getInfo: true });

      // console.log(response);

      if (response.data.data.attributes.metadata.qrCodeUrl) {
        if (!response.data.data.attributes.metadata.bookingStatus) {
          try {
            const tokenId = await getTokenId(transactionId);

            if (tokenId != 0) {
              const checkInTime = await rentalContract.checkInTime(tokenId);

              if (checkInTime.toNumber() === 0) {
                const transaction = await rentalContract.checkIn(tokenId);
                await transaction.wait();
                // console.log("Check-in successful");
                await fetchUpdateTransactionMetadata(transactionId);
              } 
              // else {
                // console.log('Renter has already checked in');
              // }
            }
          } catch (error) {
            console.error("Check-in failed:", error);
          }
        } else if (response.data.data.attributes.metadata.bookingStatus === 'Check In Completed') {
          try {
            const tokenId = await getTokenId(transactionId);

            if (tokenId != 0) {
              const transaction = await rentalContract.checkOut(tokenId);
              await transaction.wait();
              console.log("Check-out successful");
              await fetchUpdateTransactionMetadata(transactionId);
              window.location.href = `/sale/${transactionId}`;
            }
          } catch (error) {
            console.error("Check-out failed:", error);
          }
        }
      }
      setShowQrReader(false);
    } else {
      console.log("This user can't verify the qrCode");
      setShowQrReader(false);
    }
  }
};


  const handleQrError = (error) => {
    if (error) {
      console.error("QR Code Error:", error);
    }
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={css.accountLinksWrapper}>
          <NamedLink
            className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
          
          
        </div>
        <div className={css.customLinksWrapper}>{extraLinks}</div>
        {!hasWeb3 ? (
            <a 
              href="https://metamask.io/" 
              target="_blank" 
              rel="noopener noreferrer"
              className={`${css.submitButton} ${css.web3Button}`}
            >
              Download MetaMask
            </a>
          ) : client ? (
            <div>
              <Button 
                disabled 
                style={{ 
                  backgroundColor: 'green',
                }}
                className={`${css.submitButton} ${css.web3Button}`}
              >
                {client.account.slice(0, 6) + '...' + client.account.slice(-4)}
              </Button>
            </div>
          ) : (
            <Button 
              onClick={web3Handler}
              className={`${css.submitButton} ${css.web3Button}`}
            >
              Connect Wallet
            </Button>
          )}
          {client &&
            <Button
              onClick={() => setShowQrReader(!showQrReader)}
              style={{ marginTop:'10px' }}
              className={`${css.submitButton} ${css.web3Button}`}
            >
              {showQrReader ? 'Close QR Reader' : 'Open QR Reader'}
            </Button>
          }
          {showQrReader && (
            <div style={{ width: '100%', marginTop: '10px' }}>
              <QrReader
                delay={300}
                onResult={handleQrScan}
                onError={handleQrError}
                style={{ width: '100%' }}
              />
            </div>
          )}
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
